import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    // Attach a listener to detect the Enter key on keydown
    this.element.addEventListener('keydown', this.handleEnterPress.bind(this));
  }
  
  // Method to detect Enter key and trigger the button click
  handleEnterPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Trigger a click event on the "Set Values" button
      this.element.querySelector('button[data-action="click->results-set-multiple#fillFields"]').click();
    }
  }
  
  fillFields() {
    // Access fields directly using this.element to keep it encapsulated to the form
    const fieldId = this.element.querySelector('input[name="field_id"]').value;
    const fieldType = this.element.querySelector('input[name="field_type"]').value;
    
    // Handle Status field type differently
    let overwriteNonBlank = true;
    let statusToOverwrite = null;
    
    if (fieldType === 'Status') {
      statusToOverwrite = this.element.querySelector('select[name="overwrite_status_value"]').value;
      // If 'all' is selected, we'll overwrite all statuses
      overwriteNonBlank = statusToOverwrite === 'all';
    } else {
      // For non-Status fields, use the radio button value
      overwriteNonBlank = this.element.querySelector('input[name="overwrite_non_blank"]:checked').value === 'true';
    }
    
    let setMultipleValue;
    // Check the field type and set setMultipleValue accordingly
    if (fieldType === 'text' || fieldType === 'date' || fieldType === 'datetime' || fieldType === 'number') {
      setMultipleValue = this.element.querySelector('input[name="set_multiple_value"]').value;
    } else if (fieldType === 'text_area' || fieldType === 'Comments') {
      setMultipleValue = this.element.querySelector('textarea[name="set_multiple_value"]').value;
    } else if (fieldType === 'dropdown' || fieldType === 'Status') {
      setMultipleValue = this.element.querySelector('select[name="set_multiple_value"]').value;
    } else {
      console.log(`Unknown field type: ${fieldType}`);
    }
    
    console.log(`Setting value: ${setMultipleValue}`);
    
    // Select all input elements with data-pb-id ending in the target suffix
    const fieldsToSet = document.querySelectorAll(`[data-pb-id$="${fieldId}"]`);
    fieldsToSet.forEach(fieldToSet => {
      console.log(`Field type: ${fieldType}, Current value: ${fieldToSet.value}`);
      
      // Special handling for Status fields
      if (fieldType === 'Status') {
        // If a specific status was selected and the current field doesn't match, skip it
        if (!overwriteNonBlank && fieldToSet.value !== statusToOverwrite) {
          return;
        }
      } else {
        // For non-Status fields, check if we should skip non-blank fields
        if (!overwriteNonBlank && fieldToSet.value.trim() !== "") {
          return;
        }
      }
      
      // Update the field value
      fieldToSet.value = setMultipleValue;
      
      // Trigger the change event to reflect the value update
      const event = new Event('change', { bubbles: true });
      fieldToSet.dispatchEvent(event);
    });
    
    // Close the modal after all fields are updated
    document.dispatchEvent(new Event('modal:close'));
  }
}