import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["entries", "pagination", "loading"]
  
  initialize() {
    this.isLoading = false;
    let options = {
      rootMargin: '200px',
    }
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }
  
  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
    // Start loading content immediately when controller connects
    this.loadMore()
  }
  
  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }
  
  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }
  
  loadMore() {
    // Prevent multiple simultaneous requests
    if (this.isLoading) return
    
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (!next_page) { 
      this.paginationTarget.classList.add("hidden") // Hide pagination when no more pages
      return 
    }
    
    let url = next_page.href
    this.showLoading() // Show the loading message
    this.isLoading = true
    
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.hideLoading() // Hide the loading message
        this.isLoading = false
        
        // Automatically load next section after this one is loaded
        // Adding a small delay to prevent potential issues with rapid requests
        setTimeout(() => {
          this.loadMore()
        }, 300)
      },
      error: () => {
        this.hideLoading()
        this.isLoading = false
      }
    })
  }
  
  showLoading() {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove("hidden")
    }
  }
  
  hideLoading() {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.add("hidden")
    }
  }
}