import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tableBody"]
  
  connect() {
    console.log("Results channel controller connected")
    this.subscription = this.createSubscription()
    
    // Add reconnection handling
    this.reconnectInterval = 5000 // 5 seconds
    window.addEventListener('online', this.handleOnline.bind(this))
    
    // Start ping monitoring
    this.startPingMonitor()
  }
  
  handleOnline() {
    console.log("Network connection restored - reconnecting to channel")
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.subscription = this.createSubscription()
  }
  
  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval)
    }
    window.removeEventListener('online', this.handleOnline.bind(this))
    console.log("Disconnected from ResultsChannel")
  }
  
  startPingMonitor() {
    // Check connection every 30 seconds
    this.pingInterval = setInterval(() => {
      if (this.subscription && this.subscription.send) {
        console.log("Sending ping to verify connection")
        this.subscription.send({ action: "ping" })
      }
    }, 30000)
  }
  
  createSubscription() {
    return App.cable.subscriptions.create({ channel: "ResultsChannel" }, {
      controller: this,
      
      connected() {
        console.log("Connected to ResultsChannel")
      },
      
      disconnected() {
        console.log("Disconnected from ResultsChannel")
      },
      
      received(data) {
        if (data.action === "replace") {
          const existingRow = document.getElementById(data.dom_id)
          if (existingRow) {
            existingRow.outerHTML = data.html
            
            // Get the scrollable container
            const tableContainer = this.controller.element.closest('.overflow-x-auto')
            if (tableContainer) {
              // Save current scroll position
              const currentScrollLeft = tableContainer.scrollLeft
              
              // Perform a minimal scroll to "reset" the sticky columns
              // First scroll 1px to the right
              tableContainer.scrollLeft = currentScrollLeft + 1
              
              // Then scroll back to the original position after a brief delay
              setTimeout(() => {
                tableContainer.scrollLeft = currentScrollLeft
              }, 5)
            }
          }
        } else if (data.action === "append") {
          const tbody = this.controller.tableBodyTarget
          if (tbody) {
            tbody.insertAdjacentHTML("beforeend", data.html)
          }
        }
      }
    })
  }
}