import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.subscription = App.cable.subscriptions.create(
      { channel: "SamplesChannel" },
      {
        connected: () => {
          console.log("Connected to SamplesChannel")
        },
        disconnected: () => {
          console.log("Disconnected from SamplesChannel")
        },
        received: (data) => {
          if (data.action === "replace") {
            const existingRow = document.getElementById(data.dom_id)
            if (existingRow) {
              existingRow.outerHTML = data.html
            }
          }
        }
      }
    )
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}